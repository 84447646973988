.testimonial-carousel .react-multi-carousel-dot-list {
  bottom: -40px;
}

.testimonial-carousel .react-multi-carousel-dot button {
  border-color: rgba(255, 255, 255, 0.4);
  background: transparent;
}

.testimonial-carousel .react-multi-carousel-dot--active button {
  background: #fff;
}

.testimonial-carousel .react-multi-carousel-item {
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .testimonial-carousel .react-multi-carousel-item {
    padding-bottom: 60px;
  }
}
