@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hexagon {
  width: 600px;
  height: 560px;
  position: relative;
  margin: 0 auto;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
}


.hexagon-img {
  width: 100%;
  height: 100%;
  clip-path: polygon(25% 6.7%, 75% 6.7%, 100% 50%, 75% 93.3%, 25% 93.3%, 0% 50%);
}


.parallax-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .reverse-mobile{
    flex-direction: column-reverse;
    margin-top: 20rem;
  }
  .reverse-mobile img{
    width: 70%;
    height: 70%;
    margin-left: 5rem ;
  }

}


.react-multiple-carousel__arrow--right{
  right: calc(-1% + -2px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(-1% + 1px) !important;
}


.react-multiple-carousel__arrow{
  z-index: 0 !important;
}

.no-bullet{
  list-style: none !important;
}