/* src/components/HighlightsSection.css */
.hexagon-container {
  position: relative;
  width: 300px;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hexagon {
  width: 100%;
  height: 100%;
  background-color: #3b82f6;
  border: 5px solid #3b82f6;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
}

.hexagon-content {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hexagon-icon {
  position: absolute;
  top: -2px;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 10px;
  z-index: 999;
}

.hexagon-number {
  position: absolute;
  top: -20px;
  right: -20px;
}

.hexagon-small {
  width: 40px;
  height: 40px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  background-color: #1e3a8a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}
