/* src/components/NewsSection.css */
.slick-slide img {
  width: 100%;
  height: auto;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.bg-blue-100 {
  background-color: #ebf8ff;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.text-white {
  color: #ffffff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
